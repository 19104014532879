import { FaCheck } from "react-icons/fa";
import { removeNonLetters } from "../../Utils/govfunctions";

const SingleQuestionItem = (props) => {
  return (
    <div className="bg-white p-2 ">
      <p className="text-lg  ">
        {" "}
        {props.key_ + 1} : {props.item.title}{" "}
        <span className="text-xs ml-2 ">({props.item.score})</span>
      </p>

      <p className="m-2 font-light">
        <span className="p-2 rounded bg-faint inline-block  outline-0">
      
            <input
             name={props.item.title}
            onChange={() => {
              props.onChange({
                [removeNonLetters(props.item.title)]: props.item.score,
              });
            }}
            type="radio"
          />
        </span>
        Pass
      </p>
      
      <p className="m-2 font-light">
        <span className="p-2 rounded bg-faint mt-2 inline-block  outline-0">

 
          <input
 
            name={props.item.title}
            onChange={() => {
              props.onChange({ [removeNonLetters(props.item.title)]: 0 });
            }}
            type="radio"
          />
        </span> 
       Failed
      </p>

      { props.default &&  ( <small className="text-blue-400">Current grade: {props.default===props.item.score?"Pass":"Failed"}  ({props.default===props.item.score?props.default:"0"}) </small>)}

    </div>
  );
};

export { SingleQuestionItem };
