import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SingleQuestionItem } from "../../Components/Review/SingleQuestionItem";
import { GroupQuestionItem } from "../../Components/Review/GroupQuestionItem";
import Button from "../../Components/generic/Button";
import { useLocationHook } from "../../Hooks/useLocationHook";
import { onUpdateReviewItem } from "../../Utils/Servicess/review";
import Spinner from "../../Components/generic/Spinner";
import { removeNonLetters } from "../../Utils/govfunctions";

const ReviewEntry = () => {
  const [scores, setScores] = useState({});
  const [totalscores, settotalscore] = useState(0);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const school_ = useSelector((state) => state.selectedSchool);
  const selectedReviewItem = useSelector((state) => state.selectedReviewItem);
  const selectedReviewItemKey = useSelector(
    (state) => state.selectedReviewItemKey
  );
  const [loading, setLoading] = useState();

  const onChange = (value) => {
    setScores((prev) => ({ ...prev, ...value }));
  };

  useEffect(() => {
    document.getElementById("rot").scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setScores(selectedReviewItem.rated);
  }, [selectedReviewItem]);

  const submitReview = async () => {
    let currtime = new Date();
    const postdatat = {
      schoolname: school_.schoolname,
      index: selectedReviewItemKey,
      itemupdate: {
        ...selectedReviewItem,
        rated: scores,
        score: totalscores,
        updatedAt: currtime.toDateString(),
        status: "Completed",
      },
    };
    setLoading(true);
    try {
      var res = await onUpdateReviewItem(postdatat);
      setLoading(false);

      if (res.success) {
        toast.success(res.message);
        document.getElementById("rot").scrollTo(0, 0);

        navigate("/reviewschool");
      }
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  };
  useEffect(() => {
    let sum = 0;
    for (let i in scores) {
      let s = scores[removeNonLetters(i)];
      console.log(s, "i....");
      sum = sum + s;
    }

    settotalscore(sum);
  }, [scores]);

  return (
    <div className="bg-faint w-full p-8">
      <Spinner loading={loading} />

      <div className="bg-white flex justify-between fixed w-[100vw] shadow z-10 left-0 p-4 top-0">
        <p className=" relative   md:text-[25px] font-bold text-primary  ">
          <FaArrowLeft
            className=" ml-3 m-3 mb-3 text-primary inline "
            size={30}
            onClick={() => navigate(-1)}
          />
          {selectedReviewItem.ItemName}{" "}
        </p>

        <div className="md:p-6 p-2 font-bold flex justify-center items-center bg-faint">
          <p>
            <span className="text-xs p-2">Total score</span>
            {totalscores}/{selectedReviewItem.totalScore}
          </p>
        </div>
      </div>

      <div className="relative bg-white p-4 top-[60px]">
        <p className=" text-black text-xlg ">Assessment Questions:</p>
        {selectedReviewItem.questions.map((item, key) =>
          item.type == "single" ? (
            <SingleQuestionItem
              default={
                selectedReviewItem?.rated &&
                removeNonLetters(item.title) in selectedReviewItem.rated
                  ? selectedReviewItem.rated[removeNonLetters(item.title)]
                  : null
              }
              key_={key}
              onChange={onChange}
              item={item}
            />
          ) : (
            <GroupQuestionItem key_={key} onChange={onChange} item={item} />
          )
        )}

        <Button width={100} outlined text="Submit" onBtnClick={submitReview} />
      </div>
    </div>
  );
};

export { ReviewEntry };
