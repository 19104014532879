import { FaArrowLeft, FaExternalLinkAlt } from "react-icons/fa";
import Button from "../../Components/generic/Button";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../Components/generic/Spinner";
import { ReviewItem } from "../../Components/Review/ReviewItem";
import { onGetSchoolReview } from "../../Utils/Servicess/review";
import { SchoolDeails } from "./SchoolDetails";
import { SchoolRating } from "../../Components/Review/SchoolRating";
import { omited_reviewitem, schoolreviewQuestions } from "../../constants/data";
const ReviewSchool = () => {
  const school_ = useSelector((state) => state.selectedSchool);
  const [loading, setLoading] = useState(false);
  const [rating, setrating] = useState(0);
  const [reviewItems, setReviewItems] = useState([]);
  const HeaderItems = ["Review item title", "Score", "Status", "Last updated"];

  const getSelectedschoolReview = async () => {
    setLoading(true);
    var formdata = { schoolname: school_.schoolname };
    var res = await onGetSchoolReview(formdata);
    setLoading(false);

    //if this school is partt of those who had the leadership question missed
    if (
      res.review_items?.some((ll) =>
        ll.ItemName.toLowerCase().includes(
          "Leadership & Management".toLowerCase()
        )
      )
    ) {
      setReviewItems(res.review_items);
    } else {
      // if this school has all questions intact
      setReviewItems(res.review_items?.concat(omited_reviewitem));
    }

    //if for some reasons the review questions are not  present at all, as discovered or schools
    if (!res.review_items) {
      setReviewItems(schoolreviewQuestions);
    }
  };

  useEffect(() => {
    getSelectedschoolReview();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("rot").scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (school_.schoolname == undefined) {
      navigate("/");
      return;
    }
  }, []);

  useEffect(() => {
    let sum = 0;
    let percent = 0;
    reviewItems.map((k) => {
      sum = sum + k.score;
    });

    percent = Math.ceil((sum / 80) * 100);
    setrating(percent);
  }, [reviewItems]);

  return (
    <>
      <Spinner loading={loading} />
      <div className="p-4 bg-white shadow ">
        <p className="text-primary md:text-[25px] font-bold">
          <FaArrowLeft
            className=" ml-3 m-3 mb-3 inline text-primary"
            size={30}
            onClick={() => navigate("/dashboard/allschools")}
          />{" "}
          Review & Rating for {school_.schoolname}
        </p>
      </div>

      <div className="bg-customGray flex flex-col p-4 m-4">
        <div className=" p-3  bg-white">
          <SchoolRating percent={rating} num={Math.ceil((rating / 100) * 5)} />

          <div className="md:p-4 md:m-2 flex justify-between p-2  bg-[#999999]">
            {HeaderItems.map((item, key) => (
              <p
                className={`font-bold ${
                  key == 0 ? "w-[60%]" : ""
                } capitalize  text-white`}
              >
                {item}
              </p>
            ))}
          </div>

          {reviewItems.map((item, key) => (
            <ReviewItem key_={key} item={item} />
          ))}
        </div>

        <div className=" bg-white  flex justify-center flex-col items-center   text-sm rounded-b-md p-3">
          <h1 className=" font-semibold text-lg text-primary ">
            Link to School website/Social media
          </h1>
          <p className=" text-[15px] p-5">
            <a className=" text-blue-400  " href={school_.link}>
              <FaExternalLinkAlt
                className="text-primary inline  mx-2"
                size={20}
              />
              Visit{" "}
            </a>
          </p>

          <SchoolDeails />
        </div>
      </div>
    </>
  );
};
export { ReviewSchool };
